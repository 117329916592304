import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);
root.render(
  // <React.StrictMode>

  // </React.StrictMode>
  <RouterProvider router={router} />
);
